import React, { useEffect, useRef, createContext, useReducer } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export const EmailContext = createContext("");
export const FirstNameContext = createContext("");
export const LastNameContext = createContext("");
export const PhoneContext = createContext("");
export const AddressContext = createContext("");
export const CityContext = createContext("");
export const StateContext = createContext("");

axios.interceptors.request.use((req) => {
  const token = Cookies.get("port_key_j");
  if (token && req.url.indexOf(process.env.NEXT_PUBLIC_WEB_APP_URL) > -1) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  req.headers.Accept = "application/x.fashionphile.v1+json";
  return req;
});

axios.interceptors.response.use((res) => {
  if ("authorization" in res.headers) {
    let parts = process.env.NEXT_PUBLIC_WEB_APP_URL.split(".");
    parts[0] = "";
    let domain = parts.join(".");
    Cookies.set("port_key_j", res.headers.authorization.split(" ").pop(), {
      domain,
    });
  }
  return res;
});

export const UserProvider = ({ children }): any => {
  const useFetch = (url): any => {
    const cache = useRef({});

    const initialState = {
      status: "idle",
      error: null,
      data: [],
    };

    const [state, dispatch] = useReducer((state, action) => {
      switch (action.type) {
        case "FETCHING":
          return { ...initialState, status: "fetching" };
        case "FETCHED":
          return { ...initialState, status: "fetched", data: action.payload };
        case "FETCH_ERROR":
          return { ...initialState, status: "error", error: action.payload };
        default:
          return state;
      }
    }, initialState);

    useEffect(() => {
      let cancelRequest = false;
      if (!url) return;

      const fetchData = async (): Promise<any> => {
        dispatch({ type: "FETCHING" });
        if (cache.current[url]) {
          const data = cache.current[url];
          dispatch({ type: "FETCHED", payload: data });
        } else {
          try {
            const res = await axios(url);
            const json = res.data;
            const data = json;
            cache.current[url] = data;
            if (cancelRequest) return;
            dispatch({ type: "FETCHED", payload: data });
          } catch (error) {
            if (cancelRequest) return;
            dispatch({ type: "FETCH_ERROR", payload: error.message });
          }
        }
      };
      fetchData();
      return function cleanup(): any {
        cancelRequest = true;
      };
    }, [url]);

    return state;
  };

  const { status, data } = useFetch(
    `//${process.env.NEXT_PUBLIC_WEB_APP_URL}/api/account?include=primaryAddress`
  );

  let email, firstName, lastName, phone, address1, city, state;

  if (
    status === "fetched" &&
    (data?.data?.attributes?.email ||
      data?.data?.attributes?.firstName ||
      data?.data?.attributes?.lastName)
  ) {
    ({ email, firstName, lastName } = data?.data?.attributes);
  }

  if (
    status === "fetched" &&
    data?.included &&
    data?.included[0]?.attributes?.address1
  ) {
    ({ phone, address1, city, state } = data?.included[0]?.attributes);
  }

  return (
    <EmailContext.Provider value={email}>
      <FirstNameContext.Provider value={firstName}>
        <LastNameContext.Provider value={lastName}>
          <PhoneContext.Provider value={phone}>
            <AddressContext.Provider value={address1}>
              <CityContext.Provider value={city}>
                <StateContext.Provider value={state}>
                  {children}
                </StateContext.Provider>
              </CityContext.Provider>
            </AddressContext.Provider>
          </PhoneContext.Provider>
        </LastNameContext.Provider>
      </FirstNameContext.Provider>
    </EmailContext.Provider>
  );
};
