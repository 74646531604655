import React from "react";
import Head from "next/head";

import axios from "axios";
import Cookies from "js-cookie";
import { datadogRum } from "@datadog/browser-rum";
import { UserProvider } from "../components/context/UserContext";

import "../styles/main.scss";

axios.interceptors.request.use((req) => {
  const token = Cookies.get("port_key_j");
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  req.headers.Accept = "application/x.fashionphile.v1+json";
  return req;
});

axios.interceptors.response.use((res) => {
  let newAuthToken = null;
  if ("authorization" in res.headers) {
    newAuthToken = res.headers.authorization.split(" ").pop();
  }
  if ("x-amzn-remapped-authorization" in res.headers) {
    newAuthToken = res.headers["x-amzn-remapped-authorization"]
      .split(" ")
      .pop();
  }
  if (newAuthToken) {
    let parts = process.env.NEXT_PUBLIC_WEB_APP_URL.split(".");
    parts[0] = "";
    let domain = parts.join(".");
    Cookies.set("port_key_j", newAuthToken, { domain });
  }
  return res;
});

// datadog rum
datadogRum.init({
  applicationId: "fe79a0a9-a73f-455e-bb8e-d07126944601",
  clientToken: "pubc5719b650141599d2ac9921ee7c0f948",
  site: "datadoghq.com",
  service: "seller-portal",
  env: process.env.NODE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: process.env.DATADOG_VERSION,
  sampleRate: 100,
  trackInteractions: true,
});

const SupplierPortal = ({ Component, pageProps }): React.ReactElement => {
  return (
    <UserProvider>
      <Head>
        <script type="text/javascript" src="/tealium.js" />
        <script src="https://cdn.optimizely.com/js/18185992549.js" />
      </Head>
      <Component {...pageProps} />
    </UserProvider>
  );
};

export default SupplierPortal;
